

import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({})
export default class OrderedListAtom extends Vue {
  @Prop({ default: 'default' }) variant: string
  @Prop({ default: 'default' }) count: string
  @Prop({ default: 'Inhalt anzeigen' }) buttonText: string

  showHiddenContent() {
    this.variant = 'default';
  }
}

