

import { Component, Prop, Emit, Vue } from 'nuxt-property-decorator'

@Component({})
export default class textareaatom extends Vue {
  @Prop({ default: 'default' }) variant: string;
  @Prop({ default: 'Label' }) label: string;
  @Prop() id: string;
  @Prop() name: string;
  @Prop({ default: 'Textarea' }) placeholder: string;
  @Prop() autocomplete: string;
  @Prop() value: any;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: '' }) supportMessage: string;
  @Prop() assetsIconFile: string;
  @Prop({ default: 'valid' }) validationState: string;

  updated() {
    if ( this.supportMessage != '' ) {
      this.validationState = 'invalid'
    } else {
      this.validationState = 'valid'
    }
  }

  @Emit()
  emitTextareaValue(e) {
    return e.target.value
  }
};

