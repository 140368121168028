
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import { ButtonText } from "~/operations/messages";

@Component({})
export default class UnorderedListAtom extends Vue {
  @Prop({ default: "excerpt" }) variant: string;
  @Prop({ default: "default" }) count: string;
  @Prop({ default: ButtonText.unfold }) buttonText: string;

  ButtonText = ButtonText;

  excerptContentVisibility = false;

  @Watch("variant")
  watchVariantForOutsideChanges() {
    if (this.variant == "default") this.excerptContentVisibility = true;
    if (this.variant == "excerpt") this.excerptContentVisibility = false;
  }

  showHiddenContent() {
    if (!this.excerptContentVisibility) {
      this.excerptContentVisibility = true;
      this.variant = "default";
    } else {
      this.excerptContentVisibility = false;
      this.variant = "excerpt";
    }
  }
}
