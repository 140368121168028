
import { Component, Prop, Vue } from "nuxt-property-decorator";

@Component({})
export default class RouterLink extends Vue {
  @Prop({ required: true }) href: string | object;
  @Prop({ required: true }) title: string;
  @Prop({ default: "_self" }) target: string;
  @Prop({ default: "default" }) variant: string;
  @Prop({ default: "default" }) color: string;
  @Prop({ default: false }) underline: boolean;
  @Prop({ default: false }) externalLink: boolean;
}
