

import { Component, Prop, Emit, Vue } from 'nuxt-property-decorator'

@Component({})
export default class RadioButtonAtom extends Vue {
  @Prop({ default: 'default' }) variant: string;
  @Prop({ default: 'Label' }) label: string;
  @Prop({ required: true }) name: string;
  @Prop({ required: true }) value: string;
  @Prop() id: string;
  @Prop({ default: false }) checked: boolean;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: '' }) supportMessage: string;
  @Prop() labelIcon: string;
  @Prop({ default: 'valid' }) validationState: string;

  updated() {
    if ( this.supportMessage != '' ) {
      this.validationState = 'invalid'
    } else {
      this.validationState = 'valid'
    }
  }

  @Emit()
  emitInputValue(e) {
    return e.target.defaultValue;
  }
};

