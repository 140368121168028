import { render, staticRenderFns } from "./EsyPicture.vue?vue&type=template&id=61964224&scoped=true"
import script from "./EsyPicture.vue?vue&type=script&lang=ts"
export * from "./EsyPicture.vue?vue&type=script&lang=ts"
import style0 from "./EsyPicture.vue?vue&type=style&index=0&id=61964224&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61964224",
  null
  
)

export default component.exports