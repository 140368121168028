
import { Component, Prop, Vue } from "nuxt-property-decorator";

@Component({})
export default class Img extends Vue {
  @Prop({ required: true }) src: string;
  @Prop({ required: true }) alt: string;
  @Prop() width: string;
  @Prop() height: string;
  @Prop({ default: "lazy" }) loading: string; // loading |eager
  @Prop({ default: false }) showItemprop: boolean;

  get itemprop() {
    return this.showItemprop ? "image" : "";
  }
}
