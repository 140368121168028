

import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({})
export default class Picture extends Vue {
  @Prop() src: string
  @Prop() fallbackSrc: string
  @Prop() alt: string
  @Prop({ default: '640px' }) width: string
  @Prop({ default: '426px' }) height: string
  @Prop({ default: 'loading' }) loading: string // loading |eager
  @Prop({ default: false }) showItemprop: boolean
}

