

// Libraries
import { Vue, Component, Prop } from 'nuxt-property-decorator';

type VideoMeta = {
  name: string,
  description: string,
  uploadDate: string,
  duration: string,
};

@Component({
  jsonld() {
    return {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": this.videoMeta.name,
      "description": this.videoMeta.description,
      "thumbnailUrl": [
        this.placeholderImageSrc
      ],
      "uploadDate": this.videoMeta.uploadDate,
      "duration": this.videoMeta.duration,
      "embedUrl": this.src
    }

  }
})
export default class EmbeddedVideo extends Vue {
  @Prop() src: string;
  @Prop() placeholderImageSrc: string;
  @Prop({ default: true }) useThumbnail: boolean;
  @Prop({ default: {}, required: false }) videoMeta: VideoMeta;

  playVideo() {
    this.useThumbnail = false;
  }

}

