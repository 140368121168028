

import { Component, Prop, Emit, Vue } from 'nuxt-property-decorator'

@Component({})
export default class CheckboxAtom extends Vue {
  @Prop({ default: 'default' }) variant: string;
  @Prop() label: string;
  @Prop() id: string;
  @Prop() value: any;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: '' }) supportMessage: string;
  @Prop({ default: 'check-solid.svg' }) checkMarkIcon: string;
  @Prop({ default: 'valid' }) validationState: string;

  isChecked = false;

  updated() {
    if ( this.supportMessage != '' ) {
      this.validationState = 'invalid'
    } else {
      this.validationState = 'valid'
    }
  }

  mounted() {
    if ( this.value === true ) {
      this.isChecked = true;
    } else {
      this.isChecked = false;
    }
  }

  @Emit()
  emitInputValue(e) {
    this.isChecked = e.target.checked;
    return e.target.checked;
  }
};

