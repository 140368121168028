
import { Component, Prop, Vue } from "nuxt-property-decorator";

@Component({})
export default class Icon extends Vue {
  @Prop({ required: true }) icon: string;
  @Prop({ default: "" }) title: string;

  get getTitle() {
    return this.title ? this.title : `${this.icon} icon`;
  }
}
